var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.curriculumTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入课程名称（不可重名）",
                  maxlength: "16",
                },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类别：", prop: "catalogId" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.cascaderList,
                  props: { checkStrictly: true, value: "id", label: "title" },
                },
                model: {
                  value: _vm.formData.catalogId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "catalogId", $$v)
                  },
                  expression: "formData.catalogId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程昵称：" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入课程昵称（展示给购课学员）",
                  maxlength: "64",
                },
                model: {
                  value: _vm.formData.nick,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "nick", $$v)
                  },
                  expression: "formData.nick",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择课程类型" },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "知识点关联：" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "collapse-tags": "",
                    multiple: "",
                    placeholder: "知识点关联",
                  },
                  model: {
                    value: _vm.formData.knowledge,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "knowledge", $$v)
                    },
                    expression: "formData.knowledge",
                  },
                },
                _vm._l(_vm.knowledgeList, function (item) {
                  return _c("el-option", {
                    key: item.knowledgeId,
                    attrs: { label: item.content, value: item.knowledgeId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveCategory()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }